import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Alvearie/alvearie.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import RequestSlackForm from 'gatsby-theme-carbon/src/templates/RequestSlackForm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`If you would like access to Alvearie slack channels, please submit the form below.`}</p>
    <RequestSlackForm mdxType="RequestSlackForm" />
    <p /><p />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      